<template>
  <article>
    <div class="userPick_wrap">
      <div class="info_table">
        <div class="info_title">등급정보</div>
        <div class="info_content_wrap">
          <ul class="fx level_list gap_10">
            <template v-for="n in 8" :key="n">
              <li :class="{ active: userDetail.levelCd === `0${n}` }">
                <img :src="require(`@/assets/img/badge/${n}.png`)" />
                <div class="notice">
                  <span class="level_name">{{ $t(`level.${n}`) }}</span>
                  <span class="level_exp ffm_hani">{{
                    addCommas(expInfo[n])
                  }}</span>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <div class="info_table pd_20" v-if="userDetail.levelCd != 0">
        <template v-if="userDetail.levelCd">
          <div>
            <span class="next_level_info">
              현재 보유 경험치 :
              <span class="fc_basic">
                <span class="ffm_hani next_exp"
                  >{{ addCommas(userDetail.exp) }}EXP</span
                >
              </span></span
            >
          </div>
          <div class="info_content_wrap" id="new_level_info">
            <lvImg :level="userDetail.levelCd" />
            <div class="progress_bar_wrap">
              <div
                class="progress_bar"
                :style="{ width: `${progressBar}%` }"
              ></div>
            </div>
            <lvImg :level="Number(userDetail.levelCd) + 1" />
          </div>
          <div class="pb_10">
            <span class="next_level_info">
              다음 등급까지
              <span class="fc_basic">
                <span class="ffm_hani next_exp"
                  >{{ addCommas(diffExp) }}EXP</span
                >
              </span>
              남았습니다.</span
            >
          </div>
          <div class="info_content_wrap bottom fx">
            <span class="notice">
              <p>
                해당 등급에 필요한 경험치를 사용하여 다음 등급으로 승급합니다.
              </p>
              <p>등급 판매시에는 승급시 사용한 경험치의 90%만 돌려받습니다.</p>
            </span>
            <div>
              <template v-if="Number(userDetail.levelCd) > 1">
                <button
                  type="button"
                  class="btn_layout red mr_10"
                  @click="updateLevelDown"
                >
                  등급판매
                </button>
              </template>
              <button
                v-if="diffExp < 1"
                type="button"
                class="btn_layout basic"
                @click="updateLevelUp"
              >
                다음 등급구매
              </button>
            </div>
          </div>
        </template>
      </div>
      <log
        class="mt_10"
        @goPage="goPage"
        @setStartDate="setStartDate"
        @setEndDate="setEndDate"
        :list="list"
        :logData="logData"
        :pageInfo="pageInfo"
        :startDate="startDate"
        :endDate="endDate"
      />
    </div>
  </article>
</template>
<script>
import log from "@/components/mypage/pc/log.vue";
import { apiExpLog, apiLevelUp, apiLevelDown } from "@/api/user";
import { EXP_INFO } from "@/libs/constants";
import { addCommas } from "@/libs/utils";

export default {
  name: "exp",
  components: {
    log,
  },
  emits: ["getUserDetail", "modalOpen"],
  props: ["userDetail"],
  data() {
    return {
      logData: {
        tr: ["경험치", "내용", "일시"],
        td: ["tgtExp", "desc", "regDt"],
      },
      list: [],
      model: {
        rowCnt: 20,
        page: 1,
        searchStartDt: "",
        searchEndDt: "",
      },
      pageInfo: {
        page: 1,
        rowCnt: 20,
        totalPageCnt: 1,
      },
      options: [
        { text: "전체", value: 0 },
        { text: "사용내역", value: 1 },
        { text: "획득내역", value: 2 },
      ],
      expInfo: EXP_INFO,
      progressBar: 0,
      diffExp: 0,
      startDate: this.getDateStr(new Date().setDate(1), "yyyy-MM-dd"),
      endDate: this.getDateStr(new Date(), "yyyy-MM-dd"),
      searchMonth: this.getDateStr(new Date(), "yyyy-MM"),
    };
  },
  computed: {
    progressBar() {
      const nextExp = this.expInfo[Number(this.userDetail.levelCd) + 1];
      const nowExp = this.userDetail.exp;
      const progressRate = ((nowExp / nextExp) * 100).toFixed(0);
      if (progressRate < 100) {
        return progressRate;
      } else {
        return 100;
      }
    },
    diffExp() {
      const nextExp = this.expInfo[Number(this.userDetail.levelCd) + 1];
      const nowExp = this.userDetail.exp;
      const diffExp = nextExp - nowExp;
      if (diffExp < 0) {
        return 0;
      } else {
        return diffExp;
      }
    },
  },
  watch: {
    searchMonth: {
      handler(value) {
        const fullDate = new Date(value);
        const year = fullDate.getFullYear();
        const month = fullDate.getMonth();
        this.startDate = this.getDateStr(
          new Date(year, month, 1),
          "yyyy-MM-dd"
        );
        this.endDate = this.getDateStr(
          new Date(year, month + 1, 0),
          "yyyy-MM-dd"
        );
      },
    },
    startDate: {
      async handler() {
        await this.getExpLog(1);
      },
    },
    endDate: {
      async handler() {
        await this.getExpLog(1);
      },
    },
    "model.searchRst": {
      async handler() {
        await this.getExpLog(1);
      },
    },
  },
  methods: {
    // 다음 등급 구매
    async updateLevelUp() {
      const _confirm = confirm(
        "경험치를 차감하여 다음 등급을 구매하시겠습니까?"
      );
      if (!_confirm) {
        return;
      }
      const res = await apiLevelUp();
      if (res) {
        alert("등급 구매를 완료하였습니다.");
        this.$emit("getUserDetail");
        await this.refreshUserInfo();
        await this.getExpLog();
      }
    },
    async updateLevelDown() {
      const _confirm = confirm("등급을 판매하시겠습니까?");
      if (!_confirm) {
        return;
      }
      const res = await apiLevelDown();
      if (res) {
        alert("등급 판매를 완료하였습니다.");
        this.$emit("getUserDetail");
        await this.refreshUserInfo();
        await this.getExpLog();
      }
    },
    nextMonth() {
      this.searchMonth = new Date(this.searchMonth);
      const month = this.searchMonth.getMonth();
      this.searchMonth = this.searchMonth.setMonth(month + 1);
    },
    prevMonth() {
      this.searchMonth = new Date(this.searchMonth);
      const month = this.searchMonth.getMonth();
      this.searchMonth = this.searchMonth.setMonth(month - 1);
    },
    setMonth(month) {
      this.searchMonth = month;
    },
    setStartDate(date) {
      this.startDate = this.getDateStr(date, "yyyy-MM-dd");
    },
    setEndDate(date) {
      this.endDate = this.getDateStr(date, "yyyy-MM-dd");
    },
    onSelected(value) {
      this.model.searchRst = value;
    },
    async getExpLog(page) {
      if (page) {
        this.model.page = page;
      }
      const req = this.model;
      this.model.searchStartDt = this.startDate;
      this.model.searchEndDt = this.endDate;
      const res = await apiExpLog(req);
      if (res.list) {
        for (const item of res.list) {
          let exp = String(item.tgtExp);
          console.log(exp.indexOf("-") > -1, "points");
          item.tgtExp =
            exp.indexOf("-") > -1
              ? `-${addCommas(exp.slice(1))}`
              : addCommas(exp);
          item.desc = item.desc ? item.desc : "-";
        }
        this.pageInfo = res.pageInfo;
        // console.log(res)
        this.list = res.list;
      }
    },
    async goPage(page) {
      await this.getExpLog(page);
    },
    async search() {
      await this.getExpLog();
    },
  },
  async created() {
    await this.getExpLog();
  },
};
</script>
<style scoped>
.info_table {
  width: 100%;
  background: #fff;
  margin-top: 10px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid #ddd;
}
.info_title {
  color: #2e2d2d;
  font-size: 1.2em;
  font-weight: 700;
  text-align: center;
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

.info_content_wrap {
  padding: 20px;
}
.info_content_wrap img {
  width: 80px;
}
.info_content_wrap.bottom {
  border-top: 2px solid #e3e3e3;
  margin: 0 16px;
  padding: 15px;
  justify-content: space-between;
  font-weight: 700;
}
.info_content_wrap.bottom .notice {
  font-size: 0.9em;
  color: #afb0c0;
}

#new_level_info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.next_level_info {
  display: block;
  font-weight: 500;
  text-align: center;
}
.next_exp {
  font-weight: 900;
  font-size: 1.2em;
}
.progress_bar_wrap {
  height: 15px;
  width: 400px;
  background: #e7e8ee;
  border: 1px solid #cdcdcd;
  border-radius: 15px;
}
.progress_bar {
  height: 100%;
  border-radius: 15px;
  background: linear-gradient(to right, #e2f1ff, #94c93e);
}
.level_list {
  justify-content: center;
}
.level_list li {
  width: 10%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.level_list li > img {
  width: 60px;
}
.level_list .notice {
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  align-items: center;
}
.level_list .notice .level_name {
  font-weight: 500;
}
.level_list .notice .level_exp {
  font-weight: 900;
}
.level_list li.active {
  border-bottom: 3px solid #e74646;
}
</style>
